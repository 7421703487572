body {
  margin: 0;
  font-family: "Graphik Regular";
}

/* Eventually we'll want to use :focus-visible here to actually make this useful for accessibility */
*:focus {
  outline: none;
}

p {
  margin-bottom: 1em;
}